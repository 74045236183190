import { website } from '@getpopsure/private-constants';
import { captureException } from '@sentry/nextjs';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  FORMATTED_DEFAULT_LOCALE,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { BUSINESS_SUBDOMAIN, DEFAULT_SUBDOMAIN } from 'constants/subdomains';
import { getLatestPostsByCategory } from 'endpoint';
import { GetStaticPathsContext, GetStaticPropsContext } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import React from 'react';
import ComponentManager from 'strapi/components/ComponentManager';
import {
  StrapiAnnouncementBarData,
  StrapiHomepageData,
} from 'strapi/types/utils';
import { fetchAnnouncementBar, fetchMany, fetchOne } from 'strapi/utils/api';
import { isPageMarkedForDeindex } from 'strapi/utils/seo';
import { generateStrapiPagePaths } from 'util/generateCmsPagePaths';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';
import { validateSubdomain } from 'util/validateSubdomain';

import { AnnouncementBar } from '../../components/AnnoucementBar';
import { Homepage } from '../homepage';

const getWebsiteLdScript = () => {
  return {
    __html: `
    {
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "Feather Insurance",
      "alternateName": "Feather",
      "url" : "${website.base}",
      "publisher": { "@type": "Organization", "@id" : "${website.base}#organization"}
    }`,
  };
};

declare global {
  interface Window {
    snowplow: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    Trustpilot: unknown;
    opera: string;
    Intercom?: Intercom_.IntercomCommand;
  }
}

export type BlogPostType = {
  node: {
    id: number;
    link: string;
    title: string;
    excerpt: string;
    featuredImage?: {
      node: {
        sourceUrl: string;
        altText: string;
      };
    };
  };
};

const StrapiPage = ({ page }: { page: StrapiHomepageData }) => {
  const { seo, tags } = page;

  if (!seo) {
    throw new Error('Missing homepage metadata');
  }

  const { metaTitle, metaDescription, metaSocial, canonicalURL } = seo;
  const isMarkedForDeindex = isPageMarkedForDeindex(tags?.tags_list);

  if (!metaSocial || metaSocial.length === 0) {
    throw Error('Missing social media homepage preview data');
  }

  const [openGraphItem] = metaSocial;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          title: openGraphItem.title,
          description: openGraphItem.description,
          images: openGraphItem.image?.data
            ? [
                {
                  url: openGraphItem.image.data.attributes.url,
                  width: openGraphItem.image.data.attributes.width,
                  height: openGraphItem.image.data.attributes.height,
                  alt: openGraphItem.image.data.attributes.alternativeText,
                },
              ]
            : [],
        }}
        noindex={isMarkedForDeindex}
        canonical={canonicalURL}
      />
      <ComponentManager blocks={page.items} />
    </>
  );
};

const Home = ({
  blogPosts,
  navOffset,
  homepageStrapi,
  announcementBar,
}: {
  blogPosts: BlogPostType[];
  navOffset?: number;
  homepageStrapi: StrapiHomepageData | null;
  announcementBar: StrapiAnnouncementBarData | null;
}) => {
  const { t } = useTranslation();
  if (homepageStrapi) {
    return (
      <>
        <Head>
          <script
            type="application/ld+json"
            id="WebsiteLd"
            dangerouslySetInnerHTML={getWebsiteLdScript()} // eslint-disable-line react/no-danger
          />
        </Head>
        <div className="pt64">
          {announcementBar && <AnnouncementBar {...announcementBar} />}
          <StrapiPage page={homepageStrapi} />
        </div>
      </>
    );
  }

  /* If the current locale doesn't have a CMS-based homepage we render the hardcoded version */
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          id="WebsiteLd"
          dangerouslySetInnerHTML={getWebsiteLdScript()} // eslint-disable-line react/no-danger
        />
      </Head>
      <NextSeo
        title={t(
          'home:metadata.title',
          'Feather: Honest, simple insurance for Expats'
        )}
        description={t(
          'home:metadata.description',
          'With Feather, you get modern and flexible insurance backed by reliable customer support. Manage your policy online anytime and protect what matters most in minutes.'
        )}
      />
      <Homepage
        announcementBar={announcementBar}
        blogPosts={blogPosts}
        navOffset={navOffset}
      />
    </>
  );
};

export async function getStaticProps({
  params,
  locale,
  preview,
}: GetStaticPropsContext) {
  try {
    if (!locale) throw Error('Locale was not set during the build time');
    const subdomain = validateSubdomain(params?.subdomain);

    const i18nProps = await serverSideTranslations(
      i18nLocaleFormatter(locale) || FORMATTED_DEFAULT_LOCALE,
      ['common', 'home']
    );

    const announcementBar = await fetchAnnouncementBar({
      uid: 'homepage',
      locale,
      preview,
      subdomain,
    });

    const hasNonCmsHomepage =
      (locale === ENGLISH_LOCALE_IDENTIFIER ||
        locale === GERMAN_LOCALE_IDENTIFIER) &&
      subdomain === DEFAULT_SUBDOMAIN;

    if (hasNonCmsHomepage) {
      const blogPosts = await getLatestPostsByCategory();
      return {
        props: {
          blogPosts,
          homepageStrapi: null,
          announcementBar,
          ...i18nProps,
        },
      };
    }

    const homepageStrapi = await fetchOne(
      subdomain === BUSINESS_SUBDOMAIN ? 'homepage-businesses' : 'homepages',
      {
        uid: 'homepage',
        locale,
        preview,
      }
    );
    if (!hasNonCmsHomepage && !homepageStrapi) return { notFound: true };

    return {
      props: {
        blogPosts: [],
        homepageStrapi,
        announcementBar,
        ...i18nProps,
      },
    };
  } catch (e) {
    const message = 'Failed to build homepage during static generation';
    captureException(e, {
      extra: {
        locale,
        description: message,
      },
    });
    throw new Error(message);
  }
}

export const getStaticPaths = async ({ locales }: GetStaticPathsContext) => {
  if (!locales) throw Error('Locales undefined during the build time');

  try {
    const strapiPages = (
      await Promise.all(
        locales.map((locale) => fetchMany('homepages', { locale })).flat()
      )
    ).flat();

    const strapiBusinessPages = (
      await Promise.all(
        locales
          .map((locale) => fetchMany('homepage-businesses', { locale }))
          .flat()
      )
    ).flat();

    return {
      paths: [
        ...generateStrapiPagePaths(strapiPages, DEFAULT_SUBDOMAIN),
        ...generateStrapiPagePaths(strapiBusinessPages, BUSINESS_SUBDOMAIN),
        // [Note] en-de & de-de homepages are not on CMS, hence the manually created paths
        {
          params: { subdomain: DEFAULT_SUBDOMAIN, uid: '/' },
          locale: ENGLISH_LOCALE_IDENTIFIER,
        },
        {
          params: { subdomain: DEFAULT_SUBDOMAIN, uid: '/' },
          locale: GERMAN_LOCALE_IDENTIFIER,
        },
      ],
      fallback: false,
    };
  } catch (error) {
    const message = 'Failed to fetch CMS pages during static path generation';
    captureException(error, {
      extra: { description: message },
    });
    throw new Error(message);
  }
};

export default Home;
